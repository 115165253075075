<template>
  <div>
    <form-summary
      v-if="!isValid"
      class="form-errors alert alert-danger"
      :validator="$v.form"
      :attributes="attributes"
    />

    <CForm>
      <div class="d-flex flex-column">

        <!-- note_internal -->
        <div class="d-flex flex-column flex-md-row">
          <CTextarea
            class="w-100"
            rows="2"
            label="Internal Note:"
            placeholder="Internal Note"
            :lazy="false"
            :value.sync="$v.form.note_internal.$model"
          />
        </div>

        <div class="d-flex flex-column flex-md-row">
          <div class="form-group w-100">
            <div class="d-flex flex-gap-1 align-items-center pb-2 mb-2 border-bottom">
              <CIcon name="cil-list-rich" />
              <label class="mb-0">Checklist:</label>
            </div>
            <ul class="checklist">
              <li v-for="item in checkList">
                <label class="for-checkbox">
                  <input
                    type="checkbox"
                    v-model="form.checked_list"
                    :value="item"
                  />
                  {{ item }}
                </label>
              </li>
            </ul>
          </div>
        </div>

        <div class="d-flex flex-column flex-md-row">
          <div class="form-group w-100">
            <div class="d-flex flex-gap-1 align-items-center pb-2 mb-2 border-bottom">
              <CIcon name="cil-pen-nib" />
              <label class="mb-0">Contract:</label>
            </div>

            <div>
              <div class="mt-2 d-flex flex-column flex-md-row">
                <div class="form-group w-100">
                  <label class="d-block">Contract Language:</label>
                  <div class="contract-lang-group">
                    <div v-for="lang in allLanguages" v-bind:key="lang.value" class="form-check form-check-inline">
                      <input class="form-check-input d-none" type="radio" name="lang" :id="`lang_${lang.value}`" :value="lang.value" v-model="formContract.contract_lang">
                      <label class="form-check-label" :for="`lang_${lang.value}`">
                        <CIcon v-if="lang.value == 'en'" class="flag mr-1" :width="21" :height="16" :content="$options.flagSet['cifGb']" />
                        <CIcon v-else class="flag mr-1" :height="14" :content="$options.flagSet['cif' + lang.value.charAt(0).toUpperCase() + lang.value.slice(1)]" />
                        {{ lang.label }}
                      </label>
                    </div>
                  </div>
                </div>
              </div>

              <div class="mb-2 d-flex flex-column flex-md-row">
                <CInput
                  class="w-100 mr-2"
                  label="Name:"
                  placeholder="Name"
                  :lazy="false"
                  :value.sync="formContract.name"
                />
                <CInput
                  class="w-100"
                  label="Place:"
                  placeholder="Place"
                  :lazy="false"
                  :value.sync="formContract.place"
                />
              </div>

              <div class="d-flex flex-column flex-md-row">
                <div class="form-group w-100 mb-0">
                  <label class="d-block">Signature:</label>
                  <VueSignaturePad
                    id="signature"
                    width="100%"
                    height="200px"
                    ref="signaturePad"
                    :options="{
                      penColor: '#000',
                      onBegin: () => { $refs.signaturePad.resizeCanvas() }
                    }"
                  />
                  <a role="button" class="small" @click="$refs.signaturePad.undoSignature()">
                    <CIcon name="cil-action-undo" /> Undo the Signature
                  </a>
                </div>

                <div class="w-100"></div>
              </div>
            </div>

          </div>
        </div>

        <RestaurantContract
          ref="contract"
          :form="form"
          :formContract="formContract"
          :sign="contractSign"
          @generated="submit"
        />
      </div>
    </CForm>
  </div>
</template>

<script>
import { flagSet } from '@coreui/icons'
import { validationMixin } from "vuelidate";
// import { required, requiredIf } from "vuelidate/lib/validators";

export default {
  name: "RestaurantWizardStep7",
  flagSet,
  components: {
    RestaurantContract: () => import('@/components/RestaurantContract.vue'),
  },
  data() {
    return {
      loading: false,
      form: {},
      formContract: {},
      allLanguages: [],

      contractSign: null,
      checkList: [
        'MB of MS installeren',
        'Menukaart meenemen',
        'Sticker plakken aan de voorgevel',
        'Foto nemen van de voorgevel',
        // 'Google pagina beheren',
        // 'Facebook pagina',
        'Referenties aan de klant vragen',
        // 'Contract uploaden',
        // 'Relation op actief zetten',
      ],

      // Vuelidate-error-extractor
      attributes: {
        checked_list: 'All item on the check list'
      },
    };
  },

  mixins: [validationMixin],
  validations: {
    form: {
      note_internal: {},
      upload_contract: {},
      checked_list: {
        required: function () {
          return this.form.checked_list?.length == this.checkList?.length;
        }
      },
    },
  },

  async mounted() {
    this.form = this.getForm();
    this.formContract = this.getFormContract();

    await this.getDefaultCommission()
    await this.getAllLanguages();

    this.$nextTick(() => {
      this.$emit("loaded");
    });
  },

  computed: {
    isValid() { return !this.$v.form.$invalid },
    isDirty() { return this.$v.form.$anyDirty },
    country() {
      return this.authCountries.find((item) => item.value == (this.form.countryId || this.$store.state.countryId));
    },
  },

  methods: {
    checkIfValid(fieldName) {
      const field = this.$v.form[fieldName];
      if (!field.$dirty) {
        return null;
      }
      return !field.$invalid;
    },
    validate() {
      this.$v.$touch();
    },

    getForm() {
      const storedForm = localStorage.getItem("pending-restaurant");

      if (storedForm) {
        var form = JSON.parse(storedForm);

        form.note_internal = form.note_internal;
        form.upload_contract = form.upload_contract;
        form.checked_list = form.checked_list || [];
        form.type_commission = form.type_commission;

        return form;
      } else {
        this.$emit("reset");
      }
    },
    getFormContract() {
      const storedForm = localStorage.getItem('pending-restaurant-contract');

      if (storedForm) {
        var form = JSON.parse(storedForm);

        form.contract_lang = form.contract_lang;
        form.name = form.name;
        form.place = form.place;
        form.sign = form.sign;

        this.$refs.signaturePad.fromData(form.sign);

        return form;
      } else {
        return {
          contract_lang: this.form.lang || 'en',
          name: null,
          place: this.form?.restaurant_city,
          sign: null
        }
      }
    },


    async next() {
      if (!this.isValid) {
        window.scroll({top: 180, behavior: 'smooth'});
        this.validate();
        return;
      }

      this.$confirm({
        title: 'Confirm',
        message: `⚠️ The restaurant will be created. Are you sure you want to continue?`,
        button: { no: "No", yes: "Yes" },
        callback: async (confirm) => {
          if (confirm) {
            try {
              this.$emit("next", true, false);
              await this.generateContract();
            }
            catch (error) {
              this.$toast.error(error.message);
              this.$emit("next", false, false);
            }
          }
        }
      });
    },
    async previous() {
      this.formContract.sign = this.$refs.signaturePad.toData()

      localStorage.setItem("pending-restaurant", JSON.stringify(this.form));
      localStorage.setItem("pending-restaurant-contract", JSON.stringify(this.formContract));
      this.$emit("previous", true);
    },

    async submit(contractFileName = null) {
      this.form.upload_contract = contractFileName;
      this.formContract.sign = this.$refs.signaturePad.toData();

      localStorage.setItem("pending-restaurant", JSON.stringify(this.form));
      localStorage.setItem("pending-restaurant-contract", JSON.stringify(this.formContract));

      this.$emit("next", true, true);
    },


    async getAllLanguages() {
      this.allLanguages = JSON.parse(sessionStorage.getItem(`all-languages`)) || [];
      if (this.allLanguages.length) {
        return;
      }

      await this.$axios.get(this.$backend.SETTINGS.GET_LANGUAGES)
        .then((response) => {
          this.allLanguages = response.data;
          sessionStorage.setItem(`all-languages`, JSON.stringify(this.allLanguages));
        });
    },
    async getDefaultCommission() {
      if (!this.form.type_commission) {
        const response = await this.$axios.get(this.$backend.SETTINGS.GET_DEFAULT_COMMISSION);
        this.form.type_commission = response.status === 200 ? response.data : null;
      }
    },


    async generateContract() {
      this.$i18n.locale = this.formContract.contract_lang;
      const { isEmpty, data } = this.$refs.signaturePad.saveSignature();

      if (isEmpty) {
        // throw new Error("Please sign the contract!");

        // Save the restaurant if no contract signed
        this.submit()
      } else {
        this.contractSign = data;
        await this.$refs.contract.generatePdf();
      }
    },
  },
};
</script>

<style scoped>
ul.checklist {
  list-style: none;
  padding-left: 0.5rem;
}
ul.checklist li {
  line-height: 2;
  font-size: 1.25em;
}
ul.checklist input {
  width: 1.5rem;
  height: 1.5rem;
}
label.for-checkbox, label.for-checkbox > input {
  cursor: pointer;
}
label.for-checkbox {
  display: flex;
  align-items: center;
  gap: 0.75rem;
}
</style>

<style>
#signature {
  border: dotted 2px #e4e7ea;
  border-radius: 0.25rem;
}
.contract-lang-group .form-check-label {
  text-align: center;
  width: 100%;
  cursor: pointer;
  padding: 0.275rem;
  border: 1px solid rgba(60,60,60,.26);
  border-radius: 4px;
}

.contract-lang-group input[type=radio]:checked + .form-check-label {
  /* table-success */
  color: #0a0b18;
  background-color: #c4ebd1;
  border-color: #92daaa;
}
</style>
